import React from 'react'

import CountUpWrapper from 'src/components/CountUpWrapper'
import CountUp        from 'src/components/CountUp'
import Progress       from './Progress'

import formatNumber from 'src/utils/formatNumber'

const Goal = ({ total_raised_with_matching, goal, total_donors, completed }) =>
  <div>
    {
      total_donors == 0 && !completed &&
      <h2 className="h3 text-center mx-lg-n3">
        ✨ Be the first to donate ✨
      </h2>
    }
    <div>
      {
        goal > 0
      ? <div>
          <h2 className="h3 text-center d-flex align-items-center justify-content-center column-gap-1">
            {total_donors > 0 && '✨'}
            <span>
              <CountUpWrapper amount={total_raised_with_matching} prefix="$" />
              <small className="text-body-secondary text-nowrap fw-normal fs-4">
                {` of $${formatNumber(goal).split('.')[0]} goal`}
              </small>
            </span>
            {total_donors > 0 && '✨'}
          </h2>
          <Progress raised={total_raised_with_matching} goal={goal} />
          <p className="mb-0">
            {'raised by '}
            <CountUp startVal={total_donors} endVal={total_donors} className="fw-bold" />
            {total_donors == 1 ? ' person' : ' people'}
          </p>
        </div>
      : total_donors > 0
      ? <h2 className={`text-center text-nowrap ${total_raised_with_matching >= 1000000 ? 'h3' : ''}`}>
          {'✨ '}
          <CountUpWrapper amount={total_raised_with_matching} prefix="$" />
          {' raised ✨'}
          <small className="d-block text-body-secondary fw-normal fs-4">
            {'by '}
            <CountUp startVal={total_donors} endVal={total_donors} className="fw-bold" />
            {total_donors == 1 ? ' person' : ' people'}
          </small>
        </h2>
      : null
      }
    </div>
  </div>

export default Goal
